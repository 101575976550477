<template>
  <div class="address-main">
    <PageBread
      text="收货地址管理"
      :img="() => require('../../assets/image/address-pageIcon.png')"
    ></PageBread>
    <div v-wait="loading">
      <panelList
        :columns="[
          {
            label: '收件人',
            dataIndex: 'recipient',
          },
          {
            label: '邮编',
            dataIndex: 'post',
          },
          {
            label: '国家',
            dataIndex: 'nationName',
          },
          {
            label: '联系电话',
            dataIndex: 'tel',
          },
          {
            label: '地址',
            dataIndex: 'detailAddress',
          },
        ]"
        :list="listData"
        :btnList="[
          {
            icon: eidtIcon,
            text: '编辑',
            btnClick: editClick,
          },
          {
            icon: deleteIcon,
            text: '删除',
            btnClick: deleteClick,
          },
        ]"
      ></panelList>
    </div>

    <div style="text-align: center; margin-top: 30px; margin-bottom: 30px">
      <div
        class="nq-button"
        style="width: 50%"
        @click="$router.push('/address-add')"
      >
        新增地址
      </div>
    </div>
  </div>
</template>
<script>
import { addressInfo } from "../../utils/apis/apis";
export default {
  name: "addressList",
  data() {
    return {
      loading: false,
      pageInfo: {
        pageSize: 10,
        pageNum: 1,
      },
      listData: [],
      eidtIcon: require("../../assets/image/edit.png"),
      deleteIcon: require("../../assets/image/delete.png"),
      pageIcon: require("../../assets/image/address-pageIcon.png"),
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    getDataList() {
      this.loading = true;
      this.$reqPost(addressInfo.list, this.pageInfo).then((res) => {
        this.loading = false;
        if (res.data.code === 200) {
          this.listData = [...this.listData, ...res.data.data.list];
        }
      });
    },
    editClick(item) {
      this.$router.push("/address-eidt/" + item.id);
    },
    deleteClick(item, index) {
      this.$confirm({
        title: "确认",
        content: "确定删除当前地址吗？",
        okFn: () => {
          this.$reqDelete(addressInfo.delete + "/" + item.id).then((res) => {
            if (res.data.code === 200) {
              this.listData.splice(index, 1);
            }
          });
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .panel-item {
  word-break: break-all;
}
</style>

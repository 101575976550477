var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-main"},[_c('PageBread',{attrs:{"text":"收货地址管理","img":function () { return require('../../assets/image/address-pageIcon.png'); }}}),_c('div',{directives:[{name:"wait",rawName:"v-wait",value:(_vm.loading),expression:"loading"}]},[_c('panelList',{attrs:{"columns":[
        {
          label: '收件人',
          dataIndex: 'recipient',
        },
        {
          label: '邮编',
          dataIndex: 'post',
        },
        {
          label: '国家',
          dataIndex: 'nationName',
        },
        {
          label: '联系电话',
          dataIndex: 'tel',
        },
        {
          label: '地址',
          dataIndex: 'detailAddress',
        } ],"list":_vm.listData,"btnList":[
        {
          icon: _vm.eidtIcon,
          text: '编辑',
          btnClick: _vm.editClick,
        },
        {
          icon: _vm.deleteIcon,
          text: '删除',
          btnClick: _vm.deleteClick,
        } ]}})],1),_c('div',{staticStyle:{"text-align":"center","margin-top":"30px","margin-bottom":"30px"}},[_c('div',{staticClass:"nq-button",staticStyle:{"width":"50%"},on:{"click":function($event){return _vm.$router.push('/address-add')}}},[_vm._v(" 新增地址 ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }